<template>
  <div class="flex flex-col gap-4">
    <div v-for="(adapter, index) in model.accessories" :key="adapter.id">
      <AdapterDetails
        v-model:adapter="model.accessories[index]"
        :remove-adapter="removeAdapter"
        :is-last-element="isLastElement(index)"
        @on-adapter-change="adapterChange"
      />
    </div>
    <PlusButton
      test-id="addAdapter"
      title="Adapter hinzufügen"
      @add-element="addAdapter"
    />
  </div>
</template>
<script setup lang="ts">
import { Adapter } from '@/models/installationPoint/Accessory';
import AdapterDetails from '@/components/Forms/DeviceFormComponents/AdapterDetails.vue';
import PlusButton from '@/components/UI/Button/PlusButton.vue';
import { ModelRef } from 'vue';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';

const model: ModelRef<InstallationPoint | InspectionPoint> = defineModel({
  required: true,
});

const emit = defineEmits(['onAdapterChange']);

const addAdapter = () => {
  const adapter = new Adapter();
  model.value.accessories = [...model.value.accessories, adapter];
};

const removeAdapter = (adapter: Adapter) => {
  const index = model.value.accessories.findIndex(
    (accessory) => accessory.id === adapter.id
  );

  const accessoriesCopy = [...model.value.accessories];
  accessoriesCopy.splice(index, 1);
  adapterChange(adapter);
  model.value.accessories = accessoriesCopy;
};

const isLastElement = (index) => model.value.accessories.length === index + 1;

const adapterChange = (adapter: Adapter) => {
  // re-measuring not needed for this adapter
  if (adapter.customizedNumber != '42050') {
    emit('onAdapterChange', adapter);
  }
};
</script>
