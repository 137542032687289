<template>
  <div class="flex flex-col gap-3">
    <BaseToggle
      v-if="isMounted"
      class="absolute right-0 top-0"
      label="Kein Adapter notwendig"
      :is-checked="inspectionPoint.inspectionData?.noAdapterNeeded"
      @is-toggled="onAdapterToggle"
    />
    <template v-if="isMounted">
      <BaseSection test-id="image" heading="Fotos vom blanken Ventil">
        <ImageCapture
          ref="imageCaptureRef"
          :object-id="inspectionPoint.id"
          :object-type="ImageObjectType.VALVE"
          :show-checkbox="true"
          class="mt-3"
          prompt-title="Bitte nehmen Sie mehrere Fotos vom blanken Ventil auf."
        >
          <template #prompt>
            <div class="text-left">
              Auf den Fotos müssen folgende Details gut erkennbar sein:
              <ul>
                <li>Foto vom Ventil OHNE montiertes Thermostat</li>
                <li>Foto vom montierten Ventilkopf</li>
              </ul>
              Jeweils perspektivisch
            </div>
          </template>
        </ImageCapture>

        <div class="flex">
          <BaseFormInput
            v-model.number="inspectionPoint.inspectionData.counterPressure"
            label="Ventilgegendruck"
            inputmode="decimal"
            type="number"
            test-id="counterPressure"
            class="grow"
            :warning="counterPressureWarning"
            :validation="v$.inspectionPoint.inspectionData.counterPressure"
          />
          <div class="p-3">
            <div class="relative">
              <label
                class="absolute left-2.5 top-4 origin-[0] -translate-y-4 scale-75 text-sm dark:text-white"
              >
                Maßeinheit
              </label>
              <div
                class="px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:text-gray-300"
              >
                Newton
              </div>
            </div>
          </div>
        </div>
        <BaseInformation
          v-if="!isCounterPressureValid"
          :status="Status.ERROR"
          information-text="Der Gegendruck des Ventils ist zu hoch."
        >
          <template #button>
            <TertiaryButton
              data-testId="button_switchToNoMounting"
              icon="mdi-arrow-right"
              label="Keine Montage auswählen"
              @click="toggleIsMounted"
            />
          </template>
        </BaseInformation>
      </BaseSection>

      <BaseSection
        v-if="!inspectionPoint.inspectionData.noAdapterNeeded"
        heading="Adapter"
        test-id="adapters"
        :label="inspectionPoint?.accessories?.length.toString()"
      >
        <AccessoryList
          v-model="inspectionPoint"
          @on-adapter-change="onAdapterChange"
        />
      </BaseSection>
    </template>
    <BaseTextarea v-model:inputText="inspectionPoint.note" label="Notiz" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, provide, ref, Ref, watch } from 'vue';
import AccessoryList from '@/components/Forms/DeviceFormComponents/AccessoryList.vue';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { ImageObjectType } from '@/models/Image';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { Adapter } from '@/models/installationPoint/Accessory';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import { Status } from '@/enums/generic';
import { useValidation } from '@/composables/useValidation';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import NoMountingSelectorInspection from '@/components/Forms/DeviceFormComponents/NoMountingSelectorInspection.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import { FailedInstallationAttempt } from '@/models/installationPoint/InstallationPoint';
import { notMountedReasonsListSRT } from '@/enums/device';

const emit = defineEmits(['update-entity']);
const props = defineProps<{
  inspectionPoint: InspectionPoint;
}>();

onMounted(() => {
  if (inspectionPoint.value.accessories.length === 0) {
    const adapter = new Adapter();
    inspectionPoint.value.accessories.push(adapter);
  }
});

const isMounted = inject('isMounted') as Ref<boolean>;
const inspectionPoint = computed(() => props.inspectionPoint);

let tempAccessories = inspectionPoint.value.accessories;
const onAdapterToggle = (isToggled: boolean) => {
  inspectionPoint.value.inspectionData.noAdapterNeeded = isToggled;
  if (isToggled) {
    tempAccessories = inspectionPoint.value.accessories;
    inspectionPoint.value.accessories = [];
    return;
  }

  inspectionPoint.value.accessories = tempAccessories;
};

const counterPressureWarning = ref<string | undefined>(undefined);
provide('counterPressureWarning', counterPressureWarning);
const onAdapterChange = () => {
  inspectionPoint.value.inspectionData.counterPressure = undefined;
  counterPressureWarning.value = 'Erneute Messung erforderlich';
};
const isCounterPressureValid = computed(() => {
  return (
    !inspectionPoint.value.inspectionData.counterPressure ||
    inspectionPoint.value.inspectionData.counterPressure <= 90
  );
});

const toggleIsMounted = () => {
  isMounted.value = false;
  inspectionPoint.value.failedInstallationAttempt =
    new FailedInstallationAttempt();
  inspectionPoint.value.failedInstallationAttempt.reason =
    notMountedReasonsListSRT[1].value;
};

watch(
  () => inspectionPoint.value.inspectionData.counterPressure,
  () => {
    if (inspectionPoint.value.inspectionData.counterPressure !== undefined) {
      counterPressureWarning.value = undefined;
    }
  }
);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);
const { v$, isFormValid } = useValidation({ inspectionPoint });
defineExpose({
  isFormValid: computed(() => {
    return isFormValid.value && imageCaptureRef.value?.hasImage;
  }),
  saveImage: () => {
    return imageCaptureRef.value?.saveImage();
  },
});
</script>
