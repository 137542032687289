<template>
  <tr
    class="rounded-xl bg-light-3 text-base dark:border-dark-5 dark:bg-dark-3"
    :class="[{ 'border-b': !isLast }]"
  >
    <td
      class="max-w-sm overflow-hidden text-ellipsis whitespace-nowrap rounded-bl-xl py-4 pl-6"
      :class="[{ 'rounded-bl-xl': isLast }]"
    >
      <BaseChip
        v-if="entity?.failedInstallationAttempt"
        :icon="NOT_MOUNTED_ICON"
        type="danger"
      />
      <FunkcheckIcon :room-id="entity.roomId" :device="device" />
      {{ isPlant ? device.note : device.serialNumber }}
    </td>
    <td class="py-4">{{ device.productKey }}</td>

    <td class="relative justify-items-end rounded-br-xl px-3">
      <OptionsDropdown
        icon="mdi-dots-vertical"
        direction-x="left"
        direction="down"
        :data-testid="`${
          isPlant ? device.note : props.device.serialNumber
        }_optionsDropdown`"
      >
        <li
          v-if="!useOrderStore().isOnSiteInspection()"
          data-testid="exchangeDeviceButton"
          @click="onExchange(entity)"
        >
          <em class="mdi mdi-swap-horizontal mr-2" />Austauschen
        </li>
        <li @click="onEdit(entity)">
          <button class="mdi mdi-pencil mr-2" data-testid="editDeviceButton" />
          Bearbeiten
        </li>
        <li
          :class="[
            {
              'cursor-not-allowed text-light-disabled-text hover:bg-transparent dark:text-dark-disabled-text':
                !isPlantDeletable,
            },
          ]"
          @click="isPlantDeletable && onDelete(entity)"
        >
          <button
            class="mdi mdi-delete mr-2"
            data-testid="deleteDeviceButton"
            :disabled="!isPlantDeletable"
          />
          Löschen
        </li>
      </OptionsDropdown>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import FunkcheckIcon from '@/components/Funkcheck/FunkcheckIcon.vue';
import { DeviceClasses } from '@/models/devices/Device';
import { computed, ComputedRef } from 'vue';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { PlantDataClasses } from '@/models/plant/Plant';
import { entityTypes } from '@/enums/generic';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { NOT_MOUNTED_ICON } from '@/enums/icons';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';
import { useOrderStore } from '@/store/order/orderStore';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { useInspectionPointStore } from '@/store/entities/inspectionPointStore';
import { InspectionDeviceClasses } from '@/models/inspectionPoint/InspectionDevice';

const entity: ComputedRef<
  InstallationPoint | PlantDataClasses | InspectionPoint | undefined
> = computed(() => {
  if (props.device.type === entityTypes.plant) {
    return props.device as PlantDataClasses;
  }

  const inspectionPoint = useInspectionPointStore().inspectionPoints.get(
    props.device.inspectionPointId
  );
  if (useOrderStore().isOnSiteInspection() && inspectionPoint) {
    return useInspectionPointStore().inspectionPoints.get(
      props.device.inspectionPointId
    ) as InspectionPoint;
  }

  const installationPoint = useInstallationPointStore().installationPoints.get(
    props.device.installationPointId
  );
  return installationPoint as InstallationPoint;
});

const props = defineProps<{
  device: DeviceClasses | PlantDataClasses | InspectionDeviceClasses;
  onEdit: (arg0: InstallationPoint | PlantDataClasses) => void;
  onDelete: (arg0: InstallationPoint | PlantDataClasses) => void;
  onExchange: () => void;
  isLast: boolean;
  isPlant: boolean;
}>();

const isPlantDeletable = computed(() => {
  if (entity.value.type === entityTypes.plant) {
    return useDeviceStore().getDevicesOnPlant(entity.value.id).length === 0;
  }
  return true;
});
</script>
