<template>
  <div
    class="flex flex-col rounded-2xl border border-slate-300 px-4 py-2 dark:border-dark-3 dark:bg-dark-2/50"
  >
    <header class="flex justify-end">
      <em
        class="mdi mdi-close cursor-pointer text-2xl text-slate-500 dark:text-light-1"
        @click="removeAdapter(localAdapter)"
      />
    </header>

    <BaseDropdown
      v-model:selection="localAdapter.customizedNumber"
      label="Adapter"
      dropdown-text="Adapter wählen"
      :options-list="adapterTypeList"
      :recent-options-list="recommendedAdaptersPerSession"
    />

    <div class="relative md:flex md:gap-4">
      <BaseFormInput
        v-model="localAdapter.customizedNumber"
        class="md:basis-1/2"
        :validation="v$?.adapter.customizedNumber"
        label="KALO-Artikel"
      >
        <template #trailing>
          <span
            class="mdi mdi-qrcode-scan text-3xl"
            @click="openDeviceCodeScanner"
          />
        </template>
      </BaseFormInput>
      <BaseFormInput
        v-model="localAdapter.productKey"
        class="md:basis-1/2"
        :validation="v$?.adapter.productKey"
        label="Lieferant-Artikel"
      />
    </div>
    <BaseInformation
      v-if="showCounterPressureWarning"
      :status="Status.WARNING"
      information-text="Bitte den Ventilgegendruck erneut nach der Installation aller Adapter messen.
        Die vorherige Messung wird durch diese Angabe überschrieben."
    >
    </BaseInformation>
  </div>
  <div v-if="isCodeScanActive">
    <DeviceCodeScanner
      :device-type="AccessoryType.SRT_ADAPTER"
      @scan-result="scanResult($event)"
    />
  </div>
</template>
<script setup lang="ts">
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { Adapter } from '@/models/installationPoint/Accessory';
import { computed, inject, ref, watch, WritableComputedRef } from 'vue';
import DeviceCodeScanner from '@/components/UI/Scanner/DeviceCodeScanner.vue';
import { AccessoryType, adapterTypeList } from '@/enums/device';
import { useValidation } from '@/composables/useValidation';
import { SRTAdapterScanResult } from '@/models/scanner/ScanResult';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { Status } from '@/enums/generic';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';

const props = defineProps<{
  adapter: Adapter;
  isLastElement: boolean;
  removeAdapter: (arg0: Adapter) => void;
}>();

const emit = defineEmits(['update:adapter', 'onAdapterChange']);

const localAdapter: WritableComputedRef<Adapter> = computed({
  get() {
    return props.adapter;
  },
  set(value) {
    emit('update:adapter', value);
  },
});

const counterPressureWarning = inject(
  'counterPressureWarning'
) as WritableComputedRef<string | undefined>;
const showCounterPressureWarning = computed(
  () =>
    localAdapter.value.customizedNumber &&
    localAdapter.value.customizedNumber !== '42050' &&
    props.isLastElement &&
    counterPressureWarning.value
);

const isCodeScanActive = ref(false);
const openDeviceCodeScanner = () => {
  return (isCodeScanActive.value = true);
};

watch(
  () => localAdapter.value.customizedNumber,
  (newValue) => {
    const adapter = adapterTypeList.find(
      (adapter) => adapter.value === newValue
    );
    if (adapter) {
      localAdapter.value.productKey = adapter.productKey;
      localAdapter.value.customizedNumber = adapter.customizedNumber;
      useInstallationPointStore().addToRecentAdapterList(adapter);
      emit('onAdapterChange', adapter);
    } else {
      localAdapter.value.productKey = '';
    }
  }
);

const recommendedAdaptersPerSession = computed(() => {
  return useInstallationPointStore().recommendedAdaptersPerSession;
});

const scanResult = (scanDataObject: SRTAdapterScanResult | object) => {
  if (scanDataObject instanceof SRTAdapterScanResult) {
    localAdapter.value.productKey = scanDataObject.productKey;
    localAdapter.value.customizedNumber = scanDataObject.customizedNumber;
    localAdapter.value.unknownProperties = scanDataObject.unknownProperties;
  }

  isCodeScanActive.value = false;
  return;
};

const { v$ } = useValidation({
  adapter: localAdapter,
});
</script>
